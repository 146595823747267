
import Moveable from "vue-moveable";

export default {
  components: {
    Moveable,
  },
  methods: {
    onDrag({ transform }) {
      this.$refs.target.style.transform = transform;
    },
    onScale({ drag }) {
      this.$refs.target.style.transform = drag.transform;
    },
    onRotate({ drag }) {
      this.$refs.target.style.transform = drag.transform;
    },

    updateCanvasImage(e) {
      const self = this;
      const files = e.target.files;
      const reader = new FileReader();

      reader.onload = (e) => {
        const img = new Image();
        img.onload = function () {
          self.drawCanvasImage(img);
        };
        img.src = e.target.result;
      };

      reader.readAsDataURL(files[0]);
    },

    drawCanvasImage(img) {
      const canvas = this.$refs.target;
      canvas.width = 200;
      canvas.height = 200;

      const ctx = canvas.getContext("2d");
      ctx.globalAlpha = 0.666;
      ctx.drawImage(img, 0, 0, 200, 200);
    },
  },
};
